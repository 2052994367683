var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{attrs:{"toggleable":"xl","type":"dark"}},[_c('b-navbar-brand',{attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png")}})]),(_vm.me)?_c('NavbarContentRight',{attrs:{"userId":_vm.me.id,"searchQuery":_vm.searchQuery},on:{"searchQueryChange":function($event){_vm.searchQuery = $event}}}):_vm._e(),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}},[_c('div',{attrs:{"id":"nav-icon"}},[_c('span'),_c('span'),_c('span'),_c('span')])]),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(!_vm.formActive)?_c('b-navbar-nav',{staticClass:"nav-main"},[_c('b-nav-item',{attrs:{"to":{ name: 'ressorts' }}},[_vm._v("Gremien")]),_c('b-nav-item',{attrs:{"to":{ name: 'global-news' }}},[_vm._v("News")]),_c('b-nav-item',{attrs:{"to":{ name: 'events' }}},[_vm._v("Veranstaltungen")]),_c('b-nav-item',{attrs:{"href":"https://dsag.de/mitgliedschaft/vorteile/","target":"_blank"}},[_vm._v("Vorteile")]),_c('b-nav-item-dropdown',{attrs:{"left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Academy ")]},proxy:true}],null,false,108524377)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'gremium', params: { id: 'dsag-academy-forum' } }}},[_vm._v(" Academy Forum ")]),(_vm.me && _vm.me.state != 'GUEST' && _vm.me.state != 'NEW' && _vm.permissions['matching.show'])?_c('b-dropdown-item',{attrs:{"to":{ name: 'matching' }}},[_vm._v(" Matching-Plattform ")]):_vm._e()],1),(
          _vm.me &&
          _vm.me.company &&
          _vm.me.company.membership &&
          (_vm.me.company.membership.active) &&
          _vm.permissions['partner.show']
        )?_c('b-nav-item',{attrs:{"to":{ name: 'partner' }}},[_vm._v("Partner")]):_vm._e(),(_vm.me && _vm.checkAvailability())?_c('b-nav-item-dropdown',{staticClass:"administration",attrs:{"left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Admin ")]},proxy:true}],null,false,3626096000)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.cockpit' }}},[_vm._v(" Cockpit ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.company.list' }}},[_vm._v(" Firmen ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.user.list' }}},[_vm._v(" Mitglieder ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.gremium' }}},[_vm._v(" Gremien ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'admin.events' }}},[_vm._v(" Veranstaltungen ")])],1):_vm._e(),(_vm.me)?_c('b-nav-item-dropdown',{staticClass:"hide-desktop-nav",attrs:{"right":""},scopedSlots:_vm._u([(_vm.me)?{key:"button-content",fn:function(){return [_vm._v("Profil ")]},proxy:true}:null],null,true)},[(_vm.me)?_c('b-dropdown-item',{attrs:{"to":{
            name: 'me',
          }}},[_vm._v(" Mein Profil ")]):_vm._e(),(_vm.me.company)?_c('b-dropdown-item',{attrs:{"to":{
            name: 'mycompany',
          }}},[_vm._v(" Meine Firma ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])],1):_vm._e()],1):_vm._e(),(_vm.me && _vm.searchVisible)?_c('b-nav-form',{staticClass:"hide-mobile",class:[{ active: _vm.formActive }],on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submitForm()}}},[_c('b-form-input',{staticClass:"mr-sm-2",attrs:{"size":"sm","placeholder":"Suchen"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('b-button',{attrs:{"size":"sm","type":"submit"},on:{"click":function($event){return _vm.submitForm()}}},[_c('i',{staticClass:"fal fa-search"})])],1):_vm._e(),(_vm.me)?_c('NavbarContentRight',{attrs:{"user-id":_vm.me.id}}):_c('div',{staticClass:"navbar-nav ml-auto nav-actions"},[_c('b-button',{staticClass:"icon-right",attrs:{"variant":"orange"},on:{"click":_vm.$logout}},[_vm._v("Jetzt einloggen"),_c('i',{staticClass:"fa-regular fa-right-to-bracket"})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }