<template>
  <div id="app">
    <router-view></router-view>
    <!-- <cookie-banner /> -->
  </div>
</template>

<script>
// import CookieBanner from './components/ui/CookieBanner.vue';
export default {
  // components: { CookieBanner },
  name: "App",
  title: "DSAGNet",
  created() {
    this.$initPermissionStorage(this);
  },
};
</script>