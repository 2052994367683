<template>
  <b-navbar toggleable="xl" type="dark">
    <b-navbar-brand :to="{ name: 'home' }">
      <img src="@/assets/img/logo.png" />
    </b-navbar-brand>

    <NavbarContentRight
      v-if="me"
      :userId="me.id"
      :searchQuery="searchQuery"
      @searchQueryChange="searchQuery = $event"
    />

    <b-navbar-toggle target="nav-collapse"
      ><div id="nav-icon">
        <span></span>
        <span></span>
        <span></span>
        <span></span></div
    ></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="nav-main" v-if="!formActive">
        <b-nav-item :to="{ name: 'ressorts' }">Gremien</b-nav-item>
        <b-nav-item :to="{ name: 'global-news' }">News</b-nav-item>
        <b-nav-item :to="{ name: 'events' }">Veranstaltungen</b-nav-item>
        <b-nav-item
          href="https://dsag.de/mitgliedschaft/vorteile/"
          target="_blank"
          >Vorteile</b-nav-item
        >
        <b-nav-item-dropdown left>
          <template #button-content> Academy </template>
          <b-dropdown-item
            :to="{ name: 'gremium', params: { id: 'dsag-academy-forum' } }"
          >
            Academy Forum
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'matching' }" v-if="me && me.state != 'GUEST' && me.state != 'NEW' && permissions['matching.show']">
            Matching-Plattform
          </b-dropdown-item></b-nav-item-dropdown
        >
        <!-- <b-nav-item
          :to="{ name: 'gremium', params: { id: 'dsag-academy-forum' } }"
          >Academy</b-nav-item
        > -->
        <b-nav-item
          v-if="
            me &&
            me.company &&
            me.company.membership &&
            (me.company.membership.active) &&
            permissions['partner.show']
          "
          :to="{ name: 'partner' }"
          >Partner</b-nav-item
        >
        <b-nav-item-dropdown
          left
          v-if="me && checkAvailability()"
          class="administration"
        >
          <template #button-content> Admin </template>
          <b-dropdown-item :to="{ name: 'admin.cockpit' }">
            Cockpit
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.company.list' }">
            Firmen
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.user.list' }">
            Mitglieder
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.gremium' }">
            Gremien
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.events' }">
            Veranstaltungen
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="hide-desktop-nav" v-if="me">
          <template #button-content v-if="me">Profil </template>
          <b-dropdown-item
            v-if="me"
            :to="{
              name: 'me',
            }"
          >
            Mein Profil
          </b-dropdown-item>
          <b-dropdown-item
            v-if="me.company"
            :to="{
              name: 'mycompany',
            }"
          >
            Meine Firma
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="logout()">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-nav-form
        :class="[{ active: formActive }]"
        v-if="me && searchVisible"
        class="hide-mobile"
        @submit.stop.prevent="submitForm()"
      >
        <b-form-input
          size="sm"
          class="mr-sm-2"
          placeholder="Suchen"
          v-model="searchQuery"
        >
        </b-form-input>
        <b-button size="sm" type="submit" @click="submitForm()">
          <i class="fal fa-search"></i>
        </b-button>
      </b-nav-form>
      <NavbarContentRight v-if="me" :user-id="me.id" />
      <div class="navbar-nav ml-auto nav-actions" v-else>
        <b-button variant="orange" class="icon-right" @click="$logout"
          >Jetzt einloggen<i class="fa-regular fa-right-to-bracket"></i
        ></b-button>
      </div>
    </b-collapse>
  </b-navbar>
</template>
<script>
import NavbarContentRight from "./NavbarContentRight.vue";
import gql from "graphql-tag";

export default {
  components: {
    NavbarContentRight,
  },
  props: ["searchVisible"],
  emits: ["searchChange", "searchSubmit"],
  data() {
    return {
      formActive: false,
      searchQuery: "",
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.$emit("searchChange", this.searchQuery);
      },
    },
    me(val) {
      if (!val) {
        setTimeout(() => {
          if (!this.me) {
            this.$logout();
          }
        }, 5000);
      }
    },
  },
  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            state
            roles {
              id
            }
            company {
              id
              membership {
                id
                state
                active
                confirmed
              }
            }
          }
        }
      `,
      update(data) {
        if (data === undefined) {
          setTimeout(() => {
            if (!this.me) {
              this.$logout();
            }
          }, 1000);
        }
        return data.me;
      },
      fetchPolicy: "network-only",
    },
  },
  methods: {
    checkAvailability() {
      let roles = JSON.parse(JSON.stringify(this.me.roles));
      let rolesMaped = roles.map(({ id }) => id);
      if (rolesMaped.includes(process.env.VUE_APP_GS_ROLE_ID)) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      this.$logout();
    },
    submitForm() {
      this.$emit("searchSubmit", this.searchQuery);
    },
  },
  loadPermissions() {
    return ["admin.show", "partnerDb.admin", "matching.admin", "matching.show", "partner.show"];
  },
};
</script>
